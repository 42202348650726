var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { width: _vm.width, persistent: "" },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            { attrs: { height: "fit-content" } },
            [
              _c(
                "v-card-title",
                [
                  _c("span", [_vm._v(_vm._s(_vm.title))]),
                  _c("v-spacer"),
                  _c("v-divider", { attrs: { vertical: "" } }),
                  _c(
                    "v-icon",
                    {
                      staticClass: "ml-3 py-0",
                      on: {
                        click: function ($event) {
                          return _vm.close()
                        },
                      },
                    },
                    [_vm._v("mdi-close")]
                  ),
                ],
                1
              ),
              _c("v-card-text", [
                !_vm.file
                  ? _c("div", [
                      _c(
                        "div",
                        {
                          class: [
                            "dropZone",
                            _vm.dragging ? "dropZone-over" : "",
                          ],
                          on: {
                            dragenter: function ($event) {
                              _vm.dragging = true
                            },
                            dragleave: function ($event) {
                              _vm.dragging = false
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "dropZone-info",
                              on: { drag: _vm.onChange },
                            },
                            [
                              _c(
                                "v-icon",
                                { staticClass: "dropZone-title pb-2" },
                                [_vm._v("mdi-file-upload")]
                              ),
                              _c("span", { staticClass: "dropZone-title" }, [
                                _vm._v(
                                  "Datei ziehen und ablegen oder auswählen"
                                ),
                              ]),
                              _c(
                                "div",
                                { staticClass: "dropZone-upload-limit-info" },
                                [
                                  _c("div", [
                                    _vm._v(
                                      "Dateitypen: " + _vm._s(_vm.acceptTypes)
                                    ),
                                  ]),
                                  _c("div", [
                                    _vm._v("Maximale Dateigröße: 5MB "),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c("input", {
                            attrs: { type: "file", accept: _vm.acceptTypes },
                            on: { change: _vm.onChange },
                          }),
                        ]
                      ),
                    ])
                  : _c("div", { staticClass: "dropZone-uploaded" }, [
                      _c(
                        "div",
                        { staticClass: "dropZone-uploaded-info" },
                        [
                          _c("div", { staticClass: "uploadedFile-info" }, [
                            _c("div", [
                              _vm._v("Dateiname: " + _vm._s(_vm.file.name)),
                            ]),
                            _c("div", [
                              _vm._v(
                                "Dateigröße(bytes): " + _vm._s(_vm.file.size)
                              ),
                            ]),
                            _c("div", [
                              _vm._v("Dateitypen：" + _vm._s(_vm.extension)),
                            ]),
                          ]),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mt-2 removeFile",
                              attrs: { type: "button", color: "primary" },
                              on: { click: _vm.removeFile },
                            },
                            [_vm._v(" Datei entfernen")]
                          ),
                        ],
                        1
                      ),
                    ]),
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.close()
                        },
                      },
                    },
                    [_vm._v("Abbrechen")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", disabled: _vm.disable },
                      on: {
                        click: function ($event) {
                          return _vm.importExcel()
                        },
                      },
                    },
                    [_vm._v("Importieren")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }