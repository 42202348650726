<template>
    <div>
        <v-dialog v-model="dialog" :width="width" persistent>
            <v-card height="fit-content">
                <v-card-title>
                    <span>{{title}}</span>

                    <v-spacer></v-spacer>

                    <v-divider vertical></v-divider>
                    <v-icon @click="close()" class="ml-3 py-0">mdi-close</v-icon>
                </v-card-title>
                <v-card-text>
                    <div v-if="!file">
                        <div :class="['dropZone', dragging ? 'dropZone-over' : '']" @dragenter="dragging = true"
                            @dragleave="dragging = false">
                            <div class="dropZone-info" @drag="onChange">
                                <v-icon class="dropZone-title pb-2">mdi-file-upload</v-icon>
                                <span class="dropZone-title">Datei ziehen und ablegen oder auswählen</span>
                                <div class="dropZone-upload-limit-info">
                                    <div>Dateitypen: {{ acceptTypes}}</div>
                                    <div>Maximale Dateigröße: 5MB </div>
                                </div>
                            </div>
                            <input type="file" @change="onChange" :accept=" acceptTypes">
                        </div>
                    </div>
                    <div v-else class="dropZone-uploaded">
                        <div class="dropZone-uploaded-info">
                            <div class="uploadedFile-info">
                                <div>Dateiname: {{ file.name }}</div>
                                <div>Dateigröße(bytes): {{ file.size }}</div>
                                <div>Dateitypen：{{ extension }}</div>
                            </div>
                            <v-btn type="button" color="primary" class="mt-2 removeFile" @click="removeFile">
                                Datei
                                entfernen</v-btn>
                        </div>
                    </div>


                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="close()">Abbrechen</v-btn>
                    <v-btn color="primary" @click="importExcel()" :disabled="disable">Importieren</v-btn>
                </v-card-actions>

            </v-card>
        </v-dialog>

    </div>

</template>

<script>
import { customerService } from '@/services'
export default {
    name: 'sx-file-upload',

    props: {
        width: {
            type: Number,
            default: 400
        },
        accept: {
            type: String,
            default: ''
        },
        maxSize: {
            type: Number,
            // 5MB
            default: 5000000
        },
        title: {
            type: String,
            default: ''
        }
    },

    data() {
        return {
            dialog: false,
            file: '',
            dragging: false,
            disable: false,

            fileTypes: {
                excel: '.xls,.xlsx',
                csv: '.csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel',
                word: '.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                image: '.png, .jpg, .jpeg, image/png, image/jpeg',
                pdf: '.pdf',
                powerpoint: '.ppt, .pptx',
                text: '.txt'
            },
        }
    },

    computed: {
        contract: {
            get: function () {
                let contract = this.$store.getters["contract/getContract"]
                if (contract == null)
                    return { name: 'Laden...' }
                return contract
            },
            set: function () { }
        },

        acceptTypes() {
            let types = this.accept.split(',').map(item => item.trim());
            let accept = "";

            types.forEach(type => {
                switch (type) {
                    case 'excel': {
                        accept += ',' + ` ${this.fileTypes.excel}`
                        break;
                    }
                    case 'word': {
                        accept += ',' + ` ${this.fileTypes.word}`
                        break;
                    }
                    case 'pdf': {
                        accept += ',' + `${this.fileTypes.pdf}`
                        break;
                    }
                    case 'image': {
                        accept += ',' + `${this.fileTypes.image}`
                        break;
                    }
                    case 'csv': {
                        accept += ',' + `${this.fileTypes.csv}`
                        break;
                    }
                    case 'powerpoint': {
                        accept += ',' + `${this.fileTypes.powerpoint}`
                        break;
                    }
                    case 'text': {
                        accept += ',' + `${this.fileTypes.text}`
                        break;
                    }
                }
            });

            return accept;
        },

        extension() {
            return (this.file) ? this.file.name.split('.').pop() : '';
        }
    },

    methods: {
        show() {
            this.dialog = true
        },

        close() {
            this.dialog = false;
            this.file = null;
        },

        onChange(e) {
            var files = e.target.files || e.dataTransfer.files;

            if (!files.length) {
                this.dragging = false;
                return;
            }

            this.createFile(files[0]);
        },

        removeFile() {
            this.file = null;
        },

        async importExcel() {

            let excelFile = new FormData();

            excelFile.append("contract", this.contract.name);
            excelFile.append("file", this.file);
            try {
                this.disable = true;
                await customerService.excel(excelFile).then((res) => {
                    if (res.status == 200) {
                        this.close();
                    }
                    this.disable = false;
                });
            }
            catch (error) {
                console.error(error);
            }
        },

        createFile(file) {

            let extension = file.name.split('.').pop();

            if (!this.acceptTypes.includes(extension)) {
                alert(`Bitte wählen Sie folgende Dateitypen aus: ${this.acceptTypes}`);
                this.dragging = false;
                return;
            }

            if (file.size > this.maxSize) {
                alert('Die Datei ist größer als 5 MB.')
                this.dragging = false;
                return;
            }

            this.file = file;
            this.dragging = false;
        },
    }

}
</script>

<style lang="scss">
.dropZone {
    height: 20rem;
    position: relative;
    top: 10px;
    border: 2px dashed #eee;
}

.dropZone:hover {
    border: 2px solid #2e94c4;
}

.dropZone:hover .dropZone-title {
    color: #1975A0;
}

.dropZone-info {
    color: #A8A8A8;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translate(0, -50%);
    text-align: center;
}

.dropZone-title {
    color: #787878;
}

.dropZone input {
    position: absolute;
    cursor: pointer;
    top: 0px;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.dropZone-upload-limit-info {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.dropZone-over {
    background: #5C5C5C;
    opacity: 0.8;
}

.dropZone-uploaded {
    height: 200px;
    position: relative;
    border: 2px dashed #eee;
}

.dropZone-uploaded-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #A8A8A8;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translate(0, -50%);
    text-align: center;
}

.removeFile {
    width: 200px;
}
</style>